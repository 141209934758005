<template>
  <nav class="navbar is-fixed-top is-spaced" role="navigation" aria-label="main navigation">
    <div class="navbar-brand">

      <a role="button" class="navbar-burger" aria-label="menu" aria-expanded="false" data-target="navMenu">
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
        <span aria-hidden="true"></span>
      </a>
    </div>

    <div id="navMenu" class="navbar-menu">
      <div class="navbar-end">
        <a class="navbar-item" href="/#about">{{ $t('m36') }}</a>
        <a class="navbar-item" href="/#pricing">{{ $t('m32') }}</a>
        <!-- <a class="navbar-item" href="/#contact"><strong>Contact</strong></a> -->
        <a class="navbar-item" href="/#faq">FAQ</a>
        <a class="navbar-item"><font-awesome-icon :icon="['fab', 'discord']" size="lg" @click="goDiscord"/></a>

        <div class="select is-primary is-small">
          <select v-model="$i18n.locale">
            <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
          </select>
        </div>
        
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  data () {
    return { langs: ['ar','da','de','el','en','es','fi','fr','hi','id','it','ja','ko','ms','nl','pl','pt','ru','sv','tr','vi','zh-tw','zh-cn'] }
  },
  methods: {
    goDiscord() {
      window.open("https://discord.gg/Z2P4WUemmk", '_blank');
    },
    goFAQ() {
      this.$router.push('/faq').catch({});
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Add a click event on each of them
  $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
});

$(document).ready(function() {

// Check for click events on the navbar burger icon
$(".navbar-burger").click(function() {

    // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
    $(".navbar-burger").toggleClass("is-active");
    $(".navbar-menu").toggleClass("is-active");

});
});
</script>

<style>
.navbar.solid {
  background-color: #ce85ff;
}
</style>
