<template>
    <div>
        <section class="section is-medium">
        <img alt="logo" src="../assets/app_icon.png"><br>
        <div class="is-hidden-touch">
            <a class="title is-family-secondary" style="font-size: 100px;" href="/">MINTYCOOKIE</a><br>
        </div>

        <div class="is-hidden-desktop">
            <a class="title is-family-secondary" style="font-size: 70px;" href="/">MINTY<br>COOKIE</a><br>
        </div>
        <h2 class="subtitle">
            <span class="title is-4 has-text-weight-bold">CupidAI</span> connects with your soulmate anywhere in the world
        </h2>
        </section>
        <div>
            <section class="section has-text-white has-background-black">
                <h1 class="title is-3 has-text-white">Privacy Policy</h1>
                <div class="container has-text-left">
                <p>To create a MintyCookie Account, you will need to agree to the Privacy Policy below.</p>
                <p>MintyCookie is a product made by GECKOPAY TECHNOLOGY LTD.</p>            
                <p>In addition, when you create an account, we process your information as described in our Privacy Policy, including these key points:</p>
            
            <br><p>PRIVACY POLICY - GECKOPAY APPS</p>

            <br><p>This Privacy Policy explains how GECKOPAY TECHNOLOGY LTD ("we," "us," or "our") collects, uses, and protects the information collected from users ("you" or "your") when you use MintyCookie mobile applications (the "App"). By using the App, you consent to the collection, use, and disclosure of your information as described in this Privacy Policy.</p>
            <br><p>1. Information Collected:</p>
            <br><p>We may collect the following types of information from users:</p>
            <br><p>1) User ID: We collect a unique identifier assigned to each user.</p>
            <p>2) User Fullname: We may collect user fullname for app identity checker.</p>
            <p>3) User Birthday: We may collect user birthday for app age checker.</p>
            <p>4) Nickname: We may collect a nickname or username chosen by the user.</p>
            <p>5) Crash Data and Performance Data: We may collect data related to app crashes, errors, and performance to improve the App's stability and functionality.</p>
            <p>6) User's Chat and Image Message: We may collect user's chat and images to perform the app functionality.</p>
           
            <br><p>2. Purpose of Data Collection:</p>
            <br><p>We collect data for the following purposes:</p>
            <br><p>1) App Functionality: We use the collected information to provide and improve the functionality of the App, personalize user experiences, and enable specific features.</p>
            <p>2) Analytics: We may use the collected data for analytical purposes to understand user behavior, enhance the App's performance, and improve our services.</p>
            <br><p>3. User Consent:</p>
            <br><p>By using our App, you consent to the collection, use, and processing of your information as described in this Privacy Policy.</p>
            <br><p>4. Data Usage and Sharing:</p>
            <br><p>The collected data is used within our company only and is not shared with third parties, except as required by law or as necessary to provide the App's functionality. We do not sell or rent user data to third parties.</p>
            <br><p>5. Data Security:</p>
            <br><p>We are committed to protecting the security and confidentiality of your information. We implement reasonable technical and organizational measures to safeguard user data against unauthorized access, disclosure, alteration, or destruction. However, no method of transmission or electronic storage is completely secure, and we cannot guarantee absolute security.</p>
            <br><p>6. Children's Privacy:</p>
            <br><p>Our App is intended for users above the age of 19. We do not knowingly collect personal information from under the age of 19. If we become aware that we have collected personal information from under 19 without parental consent, we will take steps to delete such information.</p>
            <br><p>7. Updates to the Policy:</p>
            <br><p>We may update this Privacy Policy periodically to reflect changes in our practices or legal requirements. We will notify users of any material changes by posting the updated Privacy Policy within the App. We recommend reviewing the Privacy Policy regularly for any updates.</p>
            <br><p>8. Legal Compliance:</p>
            <br><p>Our Privacy Policy complies with relevant privacy laws and regulations, including but not limited to the General Data Protection Regulation (GDPR) in the European Union.</p>
            
            <div id="datadeletion">
                <br><p>9. Data Deletion:</p>
                <br><p>We offer 2 kinds of data deletion in MintyCookie app:</p>
                <br><p>1) User chat history deletion: the request button can be found under App Settings > Chat page</p>
                <p>2) User account deletion: the request button can be found under Account Profile > User Verification page</p>
            </div>
            
            <br><p>If you have any questions or concerns about our Privacy Policy or the handling of your information, please contact us at info@geckopay.co</p>

        </div>

            </section>
        </div>
    </div>
</template>

<script>
export default {
  name: 'privacypolicy'
}
</script>

<style>
ul.a {
  list-style-type: circle;
left: 10px;
}

</style>
