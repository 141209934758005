<template>
    <div id="pricing">
        <section class="section has-background-black has-text-left">
            <div class="container">
                <h1 class="title is-3 has-text-info is-family-secondary">{{ $t('m16') }}</h1>
                <h1 class="subtitle has-text-white">{{ $t('m41') }}</h1>
                <div class="pricing-table">
                    <div class="pricing-plan">
                        <div class="plan-header">{{ $t('m17') }}</div>
                        <div class="plan-price"><br><br><span class="plan-price-amount"><span class="plan-price-currency">$</span>0</span><br><br></div>
                        <div class="plan-items">
                        <div class="plan-item">{{ $t('m18') }}</div>
                        <div class="plan-item">{{ $t('m19') }}</div>
                        <div class="plan-item">{{ $t('m20') }}</div>
                        <div class="plan-item">{{ $t('m46') }}</div>
                        </div>
                        <div class="plan-footer">
                        <button class="button is-fullwidth" disabled="disabled">{{ $t('m21') }}</button>
                        </div>
                    </div>

                    <div class="pricing-plan is-info">
                        <div class="plan-header">{{ $t('m43') }}</div>
                        <div class="plan-price">
                            <span class="plan-price-amount">
                                <span class="plan-price-currency">$</span>20
                            </span> {{ $t('m44') }}
                            </div>
                        <div class="plan-items">
                        <div class="plan-item">{{ $t('m26') }}</div>
                        <div class="plan-item">{{ $t('m40') }}</div>
                        <div class="plan-item">{{ $t('m39') }}</div>
                        <div class="plan-item">{{ $t('m27') }}</div>
                        <div class="plan-item">{{ $t('m37') }}</div>
                        <div class="plan-item">{{ $t('m28') }}</div>
                        <div class="plan-item">{{ $t('m29') }}</div>
                        <div class="plan-item">{{ $t('m38') }}</div>
                        <div class="plan-item">{{ $t('m47') }}</div>  
                        </div>
                        <div class="plan-footer">
                        <button class="button is-fullwidth">{{ $t('m30') }}</button>
                        </div>
                    </div>
                </div>
                <p class="has-text-white is-size-7">*{{ $t('m31') }}</p>
                <p class="has-text-white is-size-7">{{ $t('m45') }}</p>
            </div>
        </section>
    </div>
</template>

<script>
export default {
  name: 'pricing'
}
</script>

<style>
ul.a {
  list-style-type: circle;
  left: 10px;
}

</style>
