<template>
    <div>
      <section class="section is-medium">
        <img alt="logo" src="../assets/app_icon.png"><br>
        <div class="is-hidden-touch">
            <a class="title is-family-secondary" style="font-size: 100px;" href="/">MINTYCOOKIE</a><br>
        </div>

        <div class="is-hidden-desktop">
            <a class="title is-family-secondary" style="font-size: 70px;" href="/">MINTY<br>COOKIE</a><br>
        </div>
        <h2 class="subtitle">
            <span class="title is-4 has-text-weight-bold">CupidAI</span> connects with your soulmate anywhere in the world
        </h2>
        </section>



        <section class="section has-text-white has-background-black">
            <h1 class="title is-3 has-text-white">Terms of Use</h1>
            <div class="container has-text-left">
                </div>
            </section>
    </div>
</template>

<script>
export default {
  name: 'faq'
}
</script>
