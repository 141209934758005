import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import router from './router'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faAngleDown, faGlobe, faAsterisk, faBolt, faCamera, faHeart, faMusic, faCrown } from '@fortawesome/free-solid-svg-icons'
import { faLinkedin, faCanadianMapleLeaf, faDiscord } from '@fortawesome/free-brands-svg-icons'
import { faMessage, faFaceSmile, faFaceGrinHearts, faFaceKiss, faFaceLaughBeam, faFaceGrinStars } from '@fortawesome/free-regular-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faAngleDown, faLinkedin, faGlobe, faCanadianMapleLeaf, faDiscord, faCrown, faMessage, faMusic, faHeart, faCamera, faBolt, faAsterisk, faFaceSmile, faFaceGrinHearts, faFaceKiss, faFaceLaughBeam, faFaceGrinStars)

Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.config.productionTip = false

require('@/assets/main.scss')

new Vue({
  i18n,
  router,
  render: h => h(App),
}).$mount('#app')
