<template>
  <div id="app">
    <div>
      <bgl-nav />
      <router-view />
      <footer class="footer">
        <div class="content has-text-centered">
          <router-link to="/termsofuse">{{ $t('m33') }}</router-link>&nbsp; &nbsp;|&nbsp;&nbsp;
          <router-link to="/privacy">{{ $t('m34') }}</router-link>&nbsp; &nbsp;|&nbsp;&nbsp;
          <a><font-awesome-icon :icon="['fab', 'discord']" size="lg" @click="goDiscord"/></a><br><br>
          <p>{{ $t('m35') }}&nbsp;<font-awesome-icon :icon="['fab', 'canadian-maple-leaf']" /> && <font-awesome-icon :icon="['fas', 'heart']" color="red"/></p>
          <p class="is-size-7">{{ new Date().getFullYear() }}  &copy; GECKOPAY TECHNOLOGY LTD. All rights reserved.</p>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import Nav from '@/components/Nav.vue';

export default {
  name: 'App',
  components: {
    'bgl-nav': Nav
  },
  methods: {
    goDiscord() {
      window.open("https://discord.gg/Z2P4WUemmk", '_blank');
    }
  }
}
</script>

<style>
body {
  overflow: hidden;
}

body::-webkit-scrollbar {
  display: none;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

</style>
