<template>
    <div>
        <section class="section is-medium">
        <img alt="logo" src="../assets/app_icon.png"><br>

        <div class="is-hidden-touch">
            <a class="title is-family-secondary" style="font-size: 100px;" href="/">MINTYCOOKIE</a><br>
        </div>

        <div class="is-hidden-desktop">
            <a class="title is-family-secondary" style="font-size: 70px;" href="/">MINTY<br>COOKIE</a><br>
        </div>
        <h2 class="subtitle">
            <span class="title is-4 has-text-weight-bold">CupidAI</span> connects with your soulmate anywhere in the world
        </h2>
        </section>
        <div>
            <section class="section has-text-white has-background-black">
            <h1 class="title is-3 has-text-white">Terms of Use</h1>
            <div class="container has-text-left">
                <p>To create a MintyCookie Account, you’ll need to agree to the Terms of Service below.</p>
                <br><p>MintyCookie is a product made by GECKOPAY TECHNOLOGY LTD.</p>            
                <br><p>END USER LICENSE AGREEMENT (EULA) - GECKOPAY APPS</p>
                <br><p>IMPORTANT: PLEASE READ THIS END USER LICENSE AGREEMENT CAREFULLY BEFORE INSTALLING OR USING MINTYCOOKIE APPS.</p>
                
                <br><p>1. ACCEPTANCE OF TERMS: This End User License Agreement (EULA) is a legal agreement between you (the "Licensee") and GECKOPAY TECHNOLOGY LTD. (the "Licensor") governing the use of MintyCookie mobile applications and websites (the "Apps"). By installing or using any of the Apps, you acknowledge that you have read, understood, and agree to be bound by the terms and conditions of this EULA.</p>
                <br><p>2. GRANT OF LICENSE: The Licensor grants the Licensee a non-exclusive, non-transferable license to use the Apps for personal, non-commercial purposes, subject to the terms and conditions of this EULA.</p>
                <br><p>3. ACCOUNT USAGE: By creating an account within one of the Apps, the Licensee acknowledges and agrees that the same account can be used in other GECKOPAY apps. This shared account functionality allows in-app tokens to be shared between apps and enables the Licensee to make one purchase to use across multiple GECKOPAY apps. However, if the Licensee requests to delete their account from one app, access to the shared account and associated features in other GECKOPAY apps will be revoked.</p>
                <br><p>4. DATA COLLECTION AND PRIVACY: The Licensor collects and processes certain data, including the Licensee's user ID, in-app purchase data, and gameplay content. The collected data is used to personalize the user experience, improve the Apps, and analyze usage patterns. The Licensor does not link this data to the Licensee's actual identity. For more information on data collection and usage, please refer to the Privacy Policy available within the Apps.</p>
                <br><p>5. INTELLECTUAL PROPERTY: The Apps, including their design, layout, graphics, and content, are protected by intellectual property laws and are the property of the Licensor or its licensors. The Licensee acknowledges and agrees that they have no ownership or proprietary rights in the Apps, except as expressly granted in this EULA.</p>
                <br><p>6. LIMITATION OF LIABILITY: In no event shall the Licensor be liable for any direct, indirect, incidental, consequential, or special damages arising out of or in connection with the use or inability to use the Apps, even if advised of the possibility of such damages.</p>
                <br><p>7. TERMINATION: This EULA is effective until terminated. The Licensee may terminate this EULA by uninstalling the Apps. The Licensor may terminate this EULA if the Licensee fails to comply with any of its terms and conditions. Upon termination, the Licensee shall cease all use of the Apps and delete any copies thereof.</p>
                <br><p>8. GOVERNING LAW: This EULA shall be governed by and construed in accordance with the laws of the jurisdiction in which the Licensor is located, without regard to its conflict of laws principles.</p>
                <br><p>9. ENTIRE AGREEMENT: This EULA constitutes the entire agreement between the Licensee and the Licensor regarding the Apps and supersedes any prior agreements or understandings, whether written or oral.</p>
                <br><p>10. AUTO-RENEWING SUBSCRIPTIONS: MintyCookie extends the option for auto-renewing subscriptions, granting users access to premium features. The subscription is processed through the Apple or Google in-app purchase system, and it will automatically renew at the end of each billing cycle unless you choose to cancel it. Any refund or cancellation of the subscription must be initiated through the respective Apple or Google refund/cancellation process.</p>
            </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
  name: 'termsofuse'
}
</script>

<style>
.text_title {
  padding: 20px;
  border: 5px solid #f1f1f1;
  color: #fff;
}

</style>
