<template>
  <div>
    <about-view />
  </div>
</template>

<script>
import AboutView from './About.vue'

export default {
  name: 'home',
  components: {
    AboutView,
  },
  
}
</script>

<style>
.display_section {
  padding: 20px;
  border: 5px solid #f1f1f1;
  color: #fff;
}

.sentence-margin {
  margin: 5px;
  padding: 10px;
}
</style>
