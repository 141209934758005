import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/home'
import PrivacyPolicy from '@/views/privacynterms'
import TermsOfUse from '@/views/termsofuse'
import FAQ from '@/views/faq'
// import Pricing from '@/views/pricing'

Vue.use(VueRouter)

export default new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/privacy',
      name: 'privaypolicy',
      component: PrivacyPolicy,
    },
    {
      path: '/termsofuse',
      name: 'termsofuse',
      component: TermsOfUse,
    }
    // ,
    // {
    //   path: '/faq',
    //   name: 'faq',
    //   component: FAQ,
    // }
    // ,
    // {
    //   path: '/pricing',
    //   name: 'pricing',
    //   component: Pricing,
    // }
  ],
  scrollBehavior() {
    window.scrollTo(0,0);
  }
})
