<template>
  <div>
    <section class="section is-medium">
      <img alt="logo" src="../assets/app_icon.png"><br>
      <div class="is-hidden-touch">
        <a class="title is-family-secondary" style="font-size: 100px;" href="/">MINTYCOOKIE</a><br>
      </div>

      <div class="is-hidden-desktop">
        <a class="title is-family-secondary" style="font-size: 70px;" href="/">MINTY<br>COOKIE</a><br>
      </div>

      <p class="subtitle"><span class="title is-4 has-text-weight-bold">CupidAI</span> {{ $t('m1') }}</p>
      <p class="subtitle is-6">{{ $t('m42') }}</p>

      <div>
        <img class="clickable-header" alt="download_1" src="../assets/appledownload.png" width=150px @click="goToTYAppStore">
        <img class="clickable-header" alt="download_1" src="../assets/googledownload.png" width=150px @click="goToTYPlayStore">
      </div>

    </section>


    <section class="section is-medium has-background-black has-text-left" id="about">
      <div class="container">
        <h1 class="title is-family-secondary is-3 has-text-info">{{ $t('m3') }}</h1>
        <div class="columns">
          <div class="column">
            <img alt="app_img_1" src="../assets/app_img_1.png" >
          </div>
          <div class="column">
            <img alt="app_img_5" src="../assets/app_img_5.png" >
          </div>
          <div class="column">
            <img alt="app_img_6" src="../assets/app_img_6.png" >
          </div>
          <div class="column">
            <img alt="app_img_7" src="../assets/app_img_7.png" >
          </div>
        </div>

        <div class="columns has-text-white">
          <div class="column">
            <p>{{ $t('m4') }}</p>
            <p>{{ $t('m5') }}</p>
          </div>
          <div class="column">
            <p>{{ $t('m6') }}</p>
          </div>
        </div>
      </div>
    </section>

    <section class="section has-background-black has-text-left">
      <div class="container">
        <h1 class="title is-family-secondary is-3 has-text-info">{{ $t('m7') }}</h1>
        <div class="columns">
          <div class="column">
            <img alt="pic_1" src="../assets/pic_1.png" >
            <img alt="pic_1" src="../assets/pic_3.png">

          </div>
          <div class="column">
            <img alt="pic_1" src="../assets/pic_4.png">
            <img alt="pic_1" src="../assets/pic_2.png">

          </div>
        </div>
      </div>
    </section>

    <section class="section is-small has-background-black">
      <div class="container">
        <div class="columns">
          <div class="column"><font-awesome-icon :icon="['fas', 'asterisk']" size="3x" color="#CE93D8" /></div>
          <div class="column"><font-awesome-icon :icon="['far', 'face-smile']" size="3x" color="#CE93D8" /></div>
          <div class="column"><font-awesome-icon :icon="['far', 'face-grin-hearts']" size="3x" color="#CE93D8" /></div>
          <div class="column"><font-awesome-icon :icon="['far', 'face-kiss']" size="3x" color="#CE93D8" /></div>
          <div class="column"><font-awesome-icon :icon="['far', 'face-laugh-beam']" size="3x" color="#CE93D8" /></div>
          <div class="column"><font-awesome-icon :icon="['far', 'face-grin-stars']" size="3x" color="#CE93D8" /></div>
          <div class="column"><font-awesome-icon :icon="['fas', 'asterisk']" size="3x" color="#CE93D8" /></div>
        </div>
      </div>
    </section>

    <section class="section is-medium has-background-black has-text-left">
      <div class="container">
        <h1 class="title is-family-secondary is-3 has-text-info">{{ $t('m8') }}</h1> 
        <div class="columns">
          <div class="column">
            <img alt="app_img_2" src="../assets/app_img_2.png">
          </div>
          <div class="column">
            <img alt="app_img_3" src="../assets/app_img_3.png">
          </div>
          <div class="column">
            <img alt="app_img_4" src="../assets/app_img_4.png">
          </div>
        </div>

        <h1 class="title is-family-secondary is-5 has-text-white">{{ $t('m9') }}</h1>
        <div class="columns has-text-white">
          <div class="column">
            <p>{{ $t('m10') }}</p>
          </div>
          <div class="column">
            <p>{{ $t('m11') }}</p>
          </div>
        </div> 
        <br>

        <h1 class="title is-family-secondary is-5 has-text-white">{{ $t('m12') }}</h1>
        <div class="columns has-text-white">
          <div class="column">
            <p>{{ $t('m13') }}</p>
          </div>
          <div class="column">
            <p>{{ $t('m14') }}</p>
          </div>
        </div>      
          
      </div>
    </section>

    <section class="section is-small has-background-black">
      <div class="container">
        <div class="columns">
          <div class="column"><font-awesome-icon :icon="['fas', 'asterisk']" size="3x" color="#CE93D8" /></div>
          <div class="column"><font-awesome-icon :icon="['fas', 'music']" size="3x" color="#CE93D8" /></div>
          <div class="column"><font-awesome-icon :icon="['fas', 'heart']" size="3x" color="#CE93D8" /></div>
          <div class="column"><font-awesome-icon :icon="['fas', 'camera']" size="3x" color="#CE93D8" /></div>
          <div class="column"><font-awesome-icon :icon="['fas', 'bolt']" size="3x" color="#CE93D8" /></div>
          <div class="column"><font-awesome-icon :icon="['far', 'message']" size="3x" color="#CE93D8" /></div>
          <div class="column"><font-awesome-icon :icon="['fas', 'asterisk']" size="3x" color="#CE93D8" /></div>
        </div>
      </div>
    </section>

    <section class="section is-small has-text-left has-background-black" id="faq">
      <div class="container">
        <h1 class="title is-family-secondary is-3 has-text-info">FAQ</h1>
        <div class="card">
          <header class="card-header has-background-info" @click="showfaq1 = !showfaq1">
            <p class="card-header-title">{{ $t('m48') }}</p>
            <button class="card-header-icon" aria-label="more options">
              <span>
                <font-awesome-icon :icon="['fas', 'angle-down']" />
              </span>
            </button>
          </header>
          <div class="card-content" v-show="showfaq1">
            <div class="content">
              <p>{{ $t('m491') }}</p><br>
              <p>{{ $t('m492') }}</p><br>
              <p>{{ $t('m493') }}</p><br>
              <p>{{ $t('m494') }}</p><br>
              <p>{{ $t('m495') }}</p>
            </div>
          </div>
        </div><br>

        <div class="card">
          <header class="card-header has-background-info" @click="showfaq2 = !showfaq2">
            <p class="card-header-title">{{ $t('m50') }}</p>
            <button class="card-header-icon" aria-label="more options">
              <span>
                <font-awesome-icon :icon="['fas', 'angle-down']" />
              </span>
            </button>
          </header>
          <div class="card-content" v-show="showfaq2">
            <div class="content">
              <p>{{ $t('m511') }}</p><br>
              <p>{{ $t('m512') }}</p><br>
              <p>{{ $t('m513') }}</p>
            </div>
          </div>
        </div><br>

        <div class="card">
          <header class="card-header has-background-info" @click="showfaq3 = !showfaq3">
            <p class="card-header-title">{{ $t('m52') }}</p>
            <button class="card-header-icon" aria-label="more options">
              <span>
                <font-awesome-icon :icon="['fas', 'angle-down']" />
              </span>
            </button>
          </header>
          <div class="card-content" v-show="showfaq3">
            <div class="content">
              <p>{{ $t('m531') }}</p><br>
              <p>{{ $t('m532') }}</p><br>
              <p>{{ $t('m533') }}</p><br>
              <p>{{ $t('m534') }}</p><br>
              <p>{{ $t('m535') }}</p>
            </div>
          </div>
        </div><br>

        <div class="card">
          <header class="card-header has-background-info" @click="showfaq4 = !showfaq4">
            <p class="card-header-title">{{ $t('m54') }}</p>
            <button class="card-header-icon" aria-label="more options">
              <span>
                <font-awesome-icon :icon="['fas', 'angle-down']" />
              </span>
            </button>
          </header>
          <div class="card-content" v-show="showfaq4">
            <div class="content">
              <p>{{ $t('m551') }}</p><br>
              <p>{{ $t('m552') }}</p><br>
              <p>{{ $t('m553') }}</p><br>
              <p>{{ $t('m554') }}</p>
            </div>
          </div>
        </div><br>

        <div class="card">
          <header class="card-header has-background-info" @click="showfaq5 = !showfaq5">
            <p class="card-header-title">{{ $t('m56') }}</p>
            <button class="card-header-icon" aria-label="more options">
              <span>
                <font-awesome-icon :icon="['fas', 'angle-down']" />
              </span>
            </button>
          </header>
          <div class="card-content" v-show="showfaq5">
            <div class="content">
              <p>{{ $t('m571') }}</p><br>
              <p>{{ $t('m572') }}</p>
            </div>
          </div>
        </div><br>

        <div class="card">
          <header class="card-header has-background-info" @click="showfaq6 = !showfaq6">
            <p class="card-header-title">{{ $t('m58') }}</p>
            <button class="card-header-icon" aria-label="more options">
              <span>
                <font-awesome-icon :icon="['fas', 'angle-down']" />
              </span>
            </button>
          </header>
          <div class="card-content" v-show="showfaq6">
            <div class="content">
              <p>{{ $t('m591') }}</p><br>
              <p>{{ $t('m592') }}</p><br>
              <p>{{ $t('m593') }}</p><br>
              <p>{{ $t('m594') }}</p>
            </div>
          </div>
        </div><br>

        <div class="card">
          <header class="card-header has-background-info" @click="showfaq7 = !showfaq7">
            <p class="card-header-title">{{ $t('m60') }}</p>
            <button class="card-header-icon" aria-label="more options">
              <span>
                <font-awesome-icon :icon="['fas', 'angle-down']" />
              </span>
            </button>
          </header>
          <div class="card-content" v-show="showfaq7">
            <div class="content">
              <p>{{ $t('m611') }}</p><br>
              <p>{{ $t('m612') }}</p><br>
              <p>{{ $t('m613') }}</p>
            </div>
          </div>
        </div><br>


        <div class="card">
          <header class="card-header has-background-info" @click="showfaq8 = !showfaq8">
            <p class="card-header-title">{{ $t('m62') }}</p>
            <button class="card-header-icon" aria-label="more options">
              <span>
                <font-awesome-icon :icon="['fas', 'angle-down']" />
              </span>
            </button>
          </header>
          <div class="card-content" v-show="showfaq8">
            <div class="content">
              <p>{{ $t('m631') }}</p><br>
              <p>{{ $t('m632') }}</p><br>
              <p>{{ $t('m633') }}</p>
            </div>
          </div>
        </div>

      </div>
    </section>

    <section class="section is-small has-background-black">
      <div class="container">
        <div class="columns">
          <div class="column"><font-awesome-icon :icon="['fas', 'asterisk']" size="3x" color="#CE93D8" /></div>
          <div class="column"><font-awesome-icon :icon="['far', 'face-smile']" size="3x" color="#CE93D8" /></div>
          <div class="column"><font-awesome-icon :icon="['far', 'face-grin-hearts']" size="3x" color="#CE93D8" /></div>
          <div class="column"><font-awesome-icon :icon="['far', 'face-kiss']" size="3x" color="#CE93D8" /></div>
          <div class="column"><font-awesome-icon :icon="['far', 'face-laugh-beam']" size="3x" color="#CE93D8" /></div>
          <div class="column"><font-awesome-icon :icon="['far', 'face-grin-stars']" size="3x" color="#CE93D8" /></div>
          <div class="column"><font-awesome-icon :icon="['fas', 'asterisk']" size="3x" color="#CE93D8" /></div>
        </div>
      </div>
    </section>


    <pricing-view />

    <section class="section is-medium has-background-info">
      <div class="container">
        <font-awesome-icon :icon="['fas', 'crown']" size="3x" color="#ffffff" /><br><br>
        <h1 class="title is-family-secondary is-1 has-text-white">{{ $t('m15') }}</h1>
        <img class="clickable-header" alt="download_1" src="../assets/appledownload.png" width=200px @click="goToTYAppStore">
        <img class="clickable-header" alt="download_1" src="../assets/googledownload.png" width=200px @click="goToTYPlayStore">
      </div>
    </section>

    <section class="section is-small has-background-black">
      <div class="container">
        <h1 class="title is-family-secondary is-3 has-text-info has-text-left">Our Team</h1>
        <h1 class="subtitle has-text-white has-text-left">We are a small team with 2 people. With just the two of us, we're crafting something we believe will spark positive change to the world :)</h1>
        <div class="columns has-text-white has-text-center">
          <div class="column">
            <img alt="girafyo_round" src="../assets/girafyo_round.png" width="50%">
            <p class="title has-text-white">Sophyawei&nbsp;<a><font-awesome-icon :icon="['fab', 'linkedin']" @click="goSophyLinkedIn"/></a></p>
            <p>Software Engineer</p>
          </div>
          <div class="column">
            <img alt="wallaby_round" src="../assets/wallaby_round.png" width="50%">
            <p class="title has-text-white">Joallaby&nbsp;<a><font-awesome-icon :icon="['fab', 'linkedin']" @click="goJacobLinkedIn"/></a></p>
            <p>Software Engineer</p>
          </div>

        </div>
      </div>
    </section>
  </div>
</template>

<script>
import PricingView from './Pricing.vue'

export default {
  name: 'about',
  components: {
    PricingView,
  },
  data() {
    return {
      showfaq1: false,
      showfaq2: false,
      showfaq3: false,
      showfaq4: false,
      showfaq5: false,
      showfaq6: false,
      showfaq7: false,
      showfaq8: false,
    }
  },
  methods: {
    goToTYAppStore() {
      window.open("https://apps.apple.com/app/mintycookie/id6449194473", '_blank');
    },
    goToTYPlayStore() {
      window.open("https://play.google.com/store/apps/details?id=co.geckopay.mintycookie", '_blank');
    },
    goDiscord() {
      window.open("https://discord.gg/Z2P4WUemmk", '_blank');
    },
    goSophyLinkedIn() {
      window.open("https://www.linkedin.com/in/yawei-sophy-hsu/", '_blank');
    },
    goJacobLinkedIn() {
      window.open("https://www.linkedin.com/in/jacob-wickland/", '_blank');
    }
  }
}

document.addEventListener('DOMContentLoaded', () => {
  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {
    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
  }
});

</script>

<style>
.clickable-header{
  cursor: pointer;
}
</style>